<template>

<div v-if="!isLogin" class="text-light mt-3">
    <span>
        No Access
        <button class="btn btn-sm btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#loginCollapse">
            Login
        </button>
        <div class="collapse" id="loginCollapse">
          <div class="border-0 cotainer mt-2" style="background: transparent;">
            <form @submit.prevent="login" class="text-start container border-bottom py-4">
    
                <div class="row m-0 col-12 col-md-6 mx-auto p-0">
                    <div class="col-12 col-md-6 p-0 pe-2">
                        <input type="email" class="form-control shadow" required v-model.lazy.trim="email" placeholder="Email">
                    </div>
                    <div class="col-12 col-md-6 p-0 ps-2">
                        <input type="password" class="form-control shadow" required v-model.lazy.trim="password" placeholder="Password">
                    </div>
                </div>
                <button type="submit" class="btn shadow btn-success mx-auto d-block mt-3 d-flex justify-content-center px-4 col-6" :disabled="loginLoading"> 
                    <Loader v-if="loginLoading" style="width: 25px;"/>
                    <span v-else> send </span>
                </button>
                
            </form>
          </div>
        </div>
    </span>
</div>


<div v-if="isLogin" id="color-container" class="d-flex flex-row align-items-center">
    

    <div class="container mx-auto border rounded blur-box position-relative">

        <div class="row m-0">
            <div class="col-12 col-md-6 pt-4 pb-3 border-end" style="border-color: rgba(0, 0, 0, 0.3) !important;">

                <h5 class="text-light"> Buy Order </h5>
                <hr class="mx-auto col-9">
                <form @submit.prevent="buyOrder" class="text-start mt-5 mb-3">

                    <div class="col-12 col-md-6 mx-auto">
                        <input type="text" class="form-control shadow mx-auto text-center" required v-model.lazy.trim="buySymbol"
                        placeholder="Symbol" @input="event =>{event.target.value = event.target.value.toUpperCase()}">

                    </div>
                    <div class="col-12 col-md-6"></div>
                    
                    <div class="col-12 col-sm-9 col-md-6 col-lg-3 col-xl-2 mt-5 mx-auto">
                        <div class="input-group mb-3">
                            <input type="number" v-model="buyFeePercent" class="form-control shadow text-center">
                            <span class="input-group-text" id="basic-addon1">%</span>
                          </div>
                    </div>

                    <div class="col-12 col-md-6 mx-auto mt-3">
                        <input type="range" max="99" min="0" step="1" class="form-range range-success pointer" v-model="buyFeePercent">
                    </div>

                    <div class="col-12 col-md-6 mx-auto mt-4">
                        <input type="number" min="0" step="any" class="form-control shadow text-center d-inline-block me-3" style="width: 45%;" v-model="TP" placeholder="TP">
                        <input type="number" min="0" step="any" class="form-control shadow text-center d-inline-block" style="width: 45%;" v-model="SL" placeholder="SL">
                    </div>

                    <hr class="mt-5 mx-auto col-9">
                    <button type="submit" class="btn shadow btn-success mx-auto col-9 d-block mt-3 d-flex justify-content-center" :disabled="buyLoading"> 
                        <Loader v-if="buyLoading" style="width: 25px;"/>
                        <span v-else> BUY </span>
                    </button>
                      
                </form>
            </div>
            
            <div class="col-12 col-md-6 pt-4 pb-3">
                <h5 class="text-light"> Sell Order </h5>
                <hr class="mx-auto col-9">
                <form @submit.prevent="sellOrder" class="text-start mt-5 mb-3">

                    <div class="col-12 col-md-6 mx-auto">
                        <input type="text" class="form-control shadow mx-auto text-center" required v-model.lazy.trim="sellSymbol"
                        placeholder="Symbol" @input="event =>{event.target.value = event.target.value.toUpperCase()}">

                    </div>
                    <div class="col-12 col-md-6"></div>
                    
                    <div class="col-12 col-sm-9 col-md-6 col-lg-3 col-xl-2 mt-5 mx-auto">
                        <div class="input-group mb-3">
                            <input type="number" v-model="sellFeePercent" class="form-control shadow text-center">
                            <span class="input-group-text" id="basic-addon1">%</span>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 mx-auto mt-3">
                        <input type="range" class="form-range range-danger pointer" v-model="sellFeePercent">
                    </div>

                    <hr class="mx-auto col-9" style="margin-top: 111px;">
                    <button type="submit" class="btn shadow btn-danger mx-auto col-9 d-block mt-3 d-flex justify-content-center" :disabled="sellLoading"> 
                        <Loader v-if="sellLoading" style="width: 25px;"/>
                        <span v-else> SELL </span>
                    </button>
                </form>
                
            </div>

        </div>

    </div>
</div>

<div v-if="response" class="container mt-4 text-light border rounded py-3">
    <span>
        status:
        <span v-if="isError" class="ms-1 text-danger bold"> FAILED </span>
        <span v-else class="ms-1 text-success bold"> SUCCESS </span>
    </span>
    <hr class="col-12 col-md-9 mx-auto">
    <div class="col-12 col-md-9 mx-auto">
        <pre class="text-start">
            {{ response }}
        </pre>
    </div>
</div>


<div v-if="historyData[0]" class="container mx-auto border rounded blur-box position-relative mt-5">
    <div class="row mb-4">
        <table class="table text-light">
            <thead>
                <tr>
                <th scope="col"> Num </th>
                <th scope="col"> Symbol </th>
                <th scope="col"> Fund Percent </th>
                <th scope="col"> Close Percent </th>
                <th scope="col"> entTime </th>
                <th scope="col"> entPrice </th>
                <th scope="col"> exitPrice </th>
                <th scope="col"> TP </th>
                <th scope="col"> SL </th>
                <th scope="col"> Is Closed </th>
                <th scope="col"> Action </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="data, index in historyData" :key="index">
                    <th scope="row"> {{ data.index }} </th>
                    <td> {{ data.symbol }} </td>
                    <td> {{ data.fund_percent }}% </td>
                    <td> {{ data.close_percent }}% </td>
                    <td> {{ data.entTime }} </td>
                    <td> {{ data.entPrice }} </td>
                    <td> {{ data.exitPrice }} </td>
                    <td> {{ data.TP }} </td>
                    <td> {{ data.SL }} </td>
                    <td> 
                        <img v-if="data.is_closed" src="./assets/check.png" alt="True" width="25">
                        <img v-else src="./assets/times.png" alt="False" width="25">
                    </td>
                    <td>
                        <button v-if="!data.is_closed" class="btn btn-primary btn-sm"
                        @click="tp_sl_selected = data" data-bs-toggle="modal" data-bs-target="#tp-sl-modal">
                            Update TP/SL
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="modal fade" id="tp-sl-modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5"> Update TP/SL for <strong>{{tp_sl_selected.symbol}}</strong> (index: {{tp_sl_selected.index}}) </h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" id="closeModal"></button>
        </div>
        <div class="modal-body">
            <div class="form-check form-check-inline mx-4 py-5">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" @click="modal_radio_value = 'TP'">
                <label class="form-check-label" for="inlineRadio1"> TP </label>
            </div>
            <div class="form-check form-check-inline mx-4">
                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" @click="modal_radio_value = 'SL'">
                <label class="form-check-label" for="inlineRadio2"> SL </label>
            </div>

            <form @submit.prevent="submitTP" v-if="modal_radio_value == 'TP'">
                <input type="text" placeholder="TP" class="form-control w-50 mx-auto" id="TpModal" required>
                <button type="submit" :disabled="TpModalLoading" class="btn btn-success btn-sm mx-auto w-50 mt-3 d-block d-flex justify-content-center">
                    <Loader v-if="TpModalLoading" style="width: 25px;"/>
                    <span v-else>Sumbit</span>
                </button>
            </form>

            <form @submit.prevent="submitSL" v-if="modal_radio_value == 'SL'">
                <input type="text" placeholder="SL" class="form-control w-50 mx-auto" id="SlModal" required>
                <button type="submit" :disabled="SlModalLoading" class="btn btn-success btn-sm mx-auto w-50 mt-3 d-block d-flex justify-content-center">
                    <Loader v-if="SlModalLoading" style="width: 25px;"/>
                    <span v-else>Sumbit</span>
                </button>
            </form>

        </div>
      </div>
    </div>
  </div>
  

</template>


<script>
import { ref } from "vue";
import Loader from './Loader.vue';
import axios from 'axios'

export default {
	setup() {

        let buyFeePercent = ref(50)
        let sellFeePercent = ref(50)
        let buyLoading = ref(false)
        let sellLoading = ref(false)
        let TpModalLoading = ref(false)
        let SlModalLoading = ref(false)
        let buySymbol = ref("")
        let sellSymbol = ref("")
        let TP = ref('')
        let SL = ref('')
        let response = ref("")
        let isError = ref(false)
        let access = localStorage.getItem("access")
        let isLogin = ref(Boolean(access))
        let loginLoading = ref(false)
        let email = ref('')
        let password = ref('')
        let historyData = ref([])
        let tp_sl_selected = ref({})
        let modal_radio_value = ref()

        if (isLogin.value){
            axios.defaults.headers.common["Authorization"] = "Bearer " + access
            getHistory()
        }


        function buyOrder(){
            buyLoading.value = true
            axios.post("trade/sabadBuy/", {
                symbol: buySymbol.value,
                SL: SL.value,
                TP: TP.value,
                fee_percent: buyFeePercent.value
            }).then((res) => {
                isError.value = false
                response.value = res.data
                buyLoading.value = false
                getHistory()
            }).catch((res) => {
                console.log(res);
                isError.value = true
                response.value = res.response.data
                buyLoading.value = false
            });
        }
        
        function getHistory(){
            axios.get("trade/sabadHistory")
            .then((res) => {
                console.log(res.data);
                historyData.value = res.data.data
            }).catch((res) => {
                if(res.response.status == 401){
                    isLogin.value = false
                    localStorage.clear()
                }else{
                    console.log(res)
                }
            });
        }
        
        function sellOrder(){
            sellLoading.value = true
            axios.post("trade/sabadSell/", {
                symbol: sellSymbol.value,
                fee_percent: sellFeePercent.value
            }).then((res) => {
                isError.value = false
                response.value = res.data
                sellLoading.value = false
                getHistory()
            }).catch((res) => {
                console.log(res);
                isError.value = true
                response.value = res.response.data
                sellLoading.value = false
            });
        }

        function login(){
            loginLoading.value = true
            axios.post("/account/login/", {
                email_or_phone: email.value,
                password: password.value
            }).then((res)=>{
                loginLoading.value = false
                localStorage.setItem("access", res.data.access)
                isLogin.value = true
				axios.defaults.headers.common["Authorization"] = "Bearer " + res.data.access
                getHistory()
            }).catch((res)=>{
                loginLoading.value = false
                alert(JSON.stringify(res.response.data))
            })
        }


        function submitTP(){
            TpModalLoading.value = true
            let inp = document.getElementById("TpModal")
            axios.post("/trade/sabadTP/", {
                id: tp_sl_selected.value.id,
                tp: inp.value
            }).then((res)=>{
                TpModalLoading.value = false
                inp.value = null
                document.getElementById("closeModal").click()
                getHistory()
            }).catch((res)=>{
                TpModalLoading.value = false
                alert(JSON.stringify(res.response.data))
            })
        }

        function submitSL(){
            SlModalLoading.value = true
            let inp = document.getElementById("SlModal")
            axios.post("/trade/sabadSL/", {
                id: tp_sl_selected.value.id,
                sl: inp.value
            }).then((res)=>{
                SlModalLoading.value = false
                inp.value = null
                document.getElementById("closeModal").click()
                getHistory()
            }).catch((res)=>{
                SlModalLoading.value = false
                alert(JSON.stringify(res.response.data))
            })
        }

        function logout(){
            localStorage.clear()
            isLogin.value = false
        }
    
		return { buyFeePercent, sellFeePercent, buyLoading, sellLoading, buyOrder, sellOrder,
            buySymbol, sellSymbol, TP, SL, response, isError, isLogin, logout, login, loginLoading,
            email, password, historyData, tp_sl_selected, modal_radio_value, TpModalLoading, SlModalLoading,
            submitTP, submitSL }

    },
    components:{Loader}

}

</script>

<style>

@font-face {
  font-family: 'Vazir';
  src: url('@/assets/fonts/Vazir.woff2') format('woff2'),
  url('@/assets/fonts/Vazir.woff') format('woff'),
  url('@/assets/fonts/Vazir.ttf') format('truetype');
}
@font-face {
  font-family: 'Vazir-Bold';
  src: url('@/assets/fonts/Vazir-Bold.woff2') format('woff2'),
  url('@/assets/fonts/Vazir-Bold.woff') format('woff'),
  url('@/assets/fonts/Vazir-Bold.ttf') format('truetype');
}

#app {
  font-family: Vazir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.swal2-popup{
	font-family: Vazir !important;
	font-size: .7rem !important;
}

.swal2-title{
	font-family: Vazir-Bold !important;
}

.bold{
  font-family: Vazir-Bold !important;
}


.pointer{
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.range-success::-webkit-slider-thumb {
  background: rgba(0, 170, 0) !important;
}

.range-success::-moz-range-thumb {
  background: rgba(0, 170, 0) !important;
}

.range-success::-ms-thumb {
  background: rgba(0, 170, 0) !important;
}


.range-danger::-webkit-slider-thumb {
  background: rgb(255, 15, 15) !important;
}

.range-danger::-moz-range-thumb {
  background: rgb(255, 15, 15) !important;
}

.range-danger::-ms-thumb {
  background: rgb(255, 15, 15) !important;
}


.blur-box{
	background: rgba( 255, 255, 255, 0.30 );
	box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
	backdrop-filter: blur( 4px );
	-webkit-backdrop-filter: blur( 4px );
	border-radius: 10px;
	border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#color-container{
	background-size: cover;
	background-position: center;
	padding-top: 1px;
	/* min-height: 95vh; */
    margin-top: 75px;
    background: #212836;  
    background: linear-gradient(-180deg, #212836, #204551);  
    background: -webkit-linear-gradient(-180deg, #212836, #204551);  
}
body{
    padding-bottom: 20px;
    background: #212836;  
    background: linear-gradient(-180deg, #212836, #204551);  
    background: -webkit-linear-gradient(-180deg, #212836, #204551);  
}

.form-check-input, .form-check-label{
    cursor: pointer;
}

</style>
